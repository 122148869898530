<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            class="mr-2"
            @click="onShowAddForm"
          >
            Add Raw Data
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowEditForm(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-lead-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-row>
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="first name"
                vid="first_name"
                rules="required|max:50"
              >
                <b-form-group>
                  <label for="first_name"><strong>First Name</strong></label>
                  <b-input
                    id="first_name"
                    v-model="facebookRaw.first_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                    placeholder="enter first name"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="last name"
                vid="last_name"
                rules="required|max:50"
              >
                <b-form-group>
                  <label for="last_name"><strong>Last Name</strong></label>
                  <b-input
                    id="last_name"
                    v-model="facebookRaw.last_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                    placeholder="enter last name"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="source"
                vid="source"
                rules="required"
              >
                <b-form-group>
                  <label for="sources"><strong>Source</strong></label>
                  <v-select
                    id="sources"
                    v-model="selected.source"
                    :options="list.sources"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.sources"
                    :loading="state.fetching.sources"
                    label="source_name"
                  >
                    <template #option="{ source_name }">
                      <strong>{{ source_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Sources
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="street name"
                vid="street_name"
                rules="max:255"
              >
                <b-form-group>
                  <label for="remarks"><strong>Street Name (Home Address)</strong></label>
                  <b-input
                    id="street_name"
                    v-model="facebookRaw.street_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter street name"
                    autocomplete="off"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="province"
                vid="province"
                rules="required"
              >
                <b-form-group>
                  <label for="province"><strong>Province (Home Address)</strong></label>
                  <v-select
                    id="province"
                    v-model="selected.province"
                    :options="list.provinces"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.provinces"
                    :loading="state.fetching.provinces"
                    label="province_name"
                  >
                    <template #option="{ province_name }">
                      <strong>{{ province_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Provinces
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="municipality"
                vid="municipality"
                rules="required"
              >
                <b-form-group>
                  <label for="municipality"><strong>Municipality (Home Address)</strong></label>
                  <v-select
                    id="municipality"
                    v-model="selected.municipality"
                    :options="list.municipalities"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.municipalities"
                    :loading="state.fetching.municipalities"
                    label="municipality_name"
                  >
                    <template #option="{ municipality_name }">
                      <strong>{{ municipality_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Municipalities
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="barangay"
                vid="barangay"
                rules=""
              >
                <b-form-group>
                  <label for="barangay"><strong>Barangay (Home Address)</strong></label>
                  <v-select
                    id="barangay"
                    v-model="selected.barangay"
                    :options="list.barangays"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    :disabled="state.busy || state.fetching.barangays"
                    :loading="state.fetching.barangays"
                    label="barangay_name"
                  >
                    <template #option="{ barangay_name }">
                      <strong>{{ barangay_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Barangays
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="required|max:50"
              >
                <b-form-group>
                  <label for="remarks"><strong>Remarks</strong></label>
                  <b-input
                    id="remarks"
                    v-model="facebookRaw.remarks"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                    placeholder="enter remarks"
                    autocomplete="off"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? "Update Record" : "Save Record" }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, UserFacebookRawDataService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'UserFacebookLeads',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Facebook Leads'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        uploading: false,
        fetching: {
          provinces: false,
          municipalities: false,
          barangays: false,
          sources: false
        }
      },
      list: {
        sources: [],
        provinces: [],
        municipalities: [],
        barangays: []
      },
      selected: {
        current: null,
        source: null,
        province: null,
        municipality: null,
        barangay: null
      },
      facebookRaw: {
        id: 0,
        first_name: '',
        last_name: '',
        source: null,
        street_name: '',
        province: null,
        municipality: null,
        barangay: null,
        remarks: ''
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'status' },
          { key: 'first_name', sortable: true },
          { key: 'last_name', sortable: true },
          { key: 'province.province_name', label: 'province' },
          { key: 'municipality.municipality_name', label: 'municipality' },
          { key: 'barangay.barangay_name', label: 'barangay' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Raw Data' : 'Add Raw Data'
    }
  },

  watch: {
    'selected.source' (value) {
      this.facebookRaw.source = value?.id || null
    },

    'selected.province' (value) {
      this.facebookRaw.province = value?.id || null
      this.getMunicipalities(this.facebookRaw.province)
    },

    'selected.municipality' (value) {
      this.facebookRaw.municipality = value?.id || null
      this.getBarangays(this.facebookRaw.municipality)
    },

    'selected.barangay' (value) {
      this.facebookRaw.barangay = value?.id || null
    }
  },

  mounted () {
    core.index()
    this.getSources()
    this.getProvinces()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await UserFacebookRawDataService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getSources () {
      this.state.fetching.sources = true
      await SharedListService.getSources().then(({ data }) => {
        this.list.sources = data
        this.state.fetching.sources = false
      })
    },

    async getProvinces () {
      this.state.fetching.provinces = true
      await SharedListService.getProvinces().then(({ data }) => {
        this.list.provinces = data
        this.state.fetching.provinces = false
      })
    },

    async getMunicipalities (province) {
      this.selected.municipality = null
      if (province) {
        this.state.fetching.municipalities = true
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.municipalities = data
          this.state.fetching.municipalities = false
          if (this.state.editing) {
            if (this.selected.current.municipality) {
              this.selected.municipality = {
                id: this.selected.current.municipality.id,
                municipality_name: this.selected.current.municipality.municipality_name
              }
              this.selected.current.municipality = null
            }
          }
        })
      } else {
        this.list.municipalities = []
      }
    },

    async getBarangays (municipality) {
      this.selected.barangay = null
      if (municipality) {
        this.state.fetching.barangays = true
        await SharedListService.getBarangays(`municipality_id=${municipality}`).then(({ data }) => {
          this.list.barangays = data
          this.state.fetching.barangays = false
          if (this.state.editing) {
            if (this.selected.current.barangay) {
              this.selected.barangay = {
                id: this.selected.current.barangay.id,
                barangay_name: this.selected.current.barangay.barangay_name
              }
              this.selected.current.barangay = null
            }
          }
        })
      } else {
        this.list.barangays = []
      }
    },

    onShowAddForm () {
      this.state.editing = false
      this.selected.source = null
      this.selected.province = null
      this.selected.municipality = null
      this.selected.barangay = null
      this.facebookRaw.id = 0
      this.facebookRaw.active = 1
      this.facebookRaw.first_name = ''
      this.facebookRaw.last_name = ''
      this.facebookRaw.source = null
      this.facebookRaw.street_name = ''
      this.facebookRaw.province = null
      this.facebookRaw.municipality = null
      this.facebookRaw.barangay = null
      this.facebookRaw.remarks = ''
      this.$bvModal.show('modal-lead-form')
    },

    onShowEditForm (current) {
      this.state.editing = true
      this.selected.current = { ...current }
      this.selected.source = null
      this.selected.province = null
      this.selected.municipality = null
      this.selected.barangay = null
      this.facebookRaw.id = current.id
      this.facebookRaw.active = current.active
      this.facebookRaw.first_name = current.first_name
      this.facebookRaw.last_name = current.last_name
      this.facebookRaw.street_name = current.street_name
      this.facebookRaw.remarks = current.remarks

      if (current.source) {
        this.selected.source = {
          id: current.source.id,
          source_name: current.source.source_name
        }
      }

      if (current.province) {
        this.selected.province = {
          id: current.province.id,
          province_name: current.province.province_name
        }
      }

      if (current.municipality) {
        this.selected.municipality = {
          id: current.municipality.id,
          municipality_name: current.municipality.municipality_name
        }
      }

      if (current.barangay) {
        this.selected.barangay = {
          id: current.barangay.id,
          barangay_name: current.barangay.barangay_name
        }
      }

      this.$bvModal.show('modal-lead-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to submit facebook raw data?'

          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserFacebookRawDataService.post(this.facebookRaw).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-lead-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserFacebookRawDataService.put(this.facebookRaw).then(({ data }) => {
          this.$bvModal.hide('modal-lead-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: data.facebook_raw.id })
            row.active = data.facebook_raw.active
            row.first_name = data.facebook_raw.first_name
            row.last_name = data.facebook_raw.last_name
            row.street_name = data.facebook_raw.street_name
            row.source = data.facebook_raw.source
            row.province = data.facebook_raw.province
            row.municipality = data.facebook_raw.municipality
            row.barangay = data.facebook_raw.barangay
            row.remarks = data.facebook_raw.remarks
            row.updated_at = data.facebook_raw.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    }
  }
}
</script>
